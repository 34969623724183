import { User, getAuth, signOut } from 'firebase/auth'
import React, { SetStateAction, useState } from 'react'
//@ts-ignore
import Logo from '../../logo-rose.svg'

type PropsType = {
  user: User,
  sideBarOpen: boolean,
  setSideBarOpen: React.Dispatch<SetStateAction<boolean>>
}

function TopBar({ user, setSideBarOpen, sideBarOpen } : PropsType) {
  const [open, setOpen] = useState(false)

  const auth = getAuth();

  return (
    <nav className="bg-white border-b border-gray-200 px-4 py-2.5  fixed left-0 right-0 top-0 z-50">
    <div className="flex flex-wrap justify-between items-center">
      <div className="flex justify-start items-center">
        <button
          data-drawer-target="drawer-navigation"
          data-drawer-toggle="drawer-navigation"
          aria-controls="drawer-navigation"
          onClick={() => setSideBarOpen(!sideBarOpen)}
          className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100  focus:ring-2 focus:ring-gray-100"
        >
          <svg
            aria-hidden="true"
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <svg
            aria-hidden="true"
            className="hidden w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Toggle sidebar</span>
        </button>
        <div className="flex items-center justify-between mr-4">
          <img
            src={Logo}
            className="mr-3 h-8"
            alt="weedlink Logo"
          />
          <span className="self-center text-2xl font-semibold whitespace-nowrap">weedlink</span>
        </div>
        <form action="#" method="GET" className="hidden md:block md:pl-2">
          <label htmlFor="topbar-search" className="sr-only">Search</label>
          <div className="hidden relative md:w-64 md:w-96">
            <div
              className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none"
            >
              <svg
                className="w-5 h-5 text-gray-500"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              name="email"
              id="topbar-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5"
              placeholder="Search"
            />
          </div>
        </form>
      </div>
      <div className="flex items-center lg:order-2">
        <button
          type="button"
          data-drawer-toggle="drawer-navigation"
          aria-controls="drawer-navigation"
          className="hidden p-2 mr-1 text-gray-500 rounded-lg md:hidden hover:text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 "
        >
          <span className="sr-only">Toggle search</span>
          <svg aria-hidden="true" className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"></path>
          </svg>
        </button>
        <button
          type="button"
          data-dropdown-toggle="notification-dropdown"
          className="hidden p-2 mr-1 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100"
        >
          <span className="sr-only">View notifications</span>
          <svg
            aria-hidden="true"
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
            ></path>
          </svg>
        </button>
       
        <button
          type="button"
          data-dropdown-toggle="apps-dropdown"
          className="hidden p-2 text-gray-500 rounded-lg hover:text-gray-900 hover:bg-gray-100 focus:ring-4 focus:ring-gray-300"
        >
          <span className="sr-only">View notifications</span>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
            ></path>
          </svg>
        </button>
        <button
          type="button"
          className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 "
          id="user-menu-button"
          aria-expanded="false"
          data-dropdown-toggle="dropdown"
          onClick={() => setOpen(!open)}
        >
          <div className='w-8 h-8 rounded-full bg-emerald-300 flex items-center justify-center'>
            <p className='font-bold text-slate-800'>{user.email?.substring(0,1).toUpperCase()}</p>
          </div>
        </button>
        <div
          className={(open ? 'absolute' : 'hidden') + " right-2 top-14 z-50 my-4 w-56 text-base list-none bg-white rounded divide-y divide-gray-100 shadow rounded-xl"}
          id="dropdown"
        >
          <div className="hidden py-3 px-4">
            <span
              className="block text-sm font-semibold text-gray-900 "
              >Neil Sims</span>
            <span
              className="block text-sm text-gray-900 truncate "
              >name@flowbite.com</span>
          </div>
          <ul
            className="hidden py-1 text-gray-700"
            aria-labelledby="dropdown"
          >
            <li>
              <a
                href="/"
                className="block py-2 px-4 text-sm hover:bg-gray-100"
                >My profile</a>
            </li>
            <li>
              <a
                href="/"
                className="block py-2 px-4 text-sm hover:bg-gray-100"
                >Account settings</a>
            </li>
          </ul>
          <ul
            className="hidden py-1 text-gray-700"
            aria-labelledby="dropdown"
          >
            <li>
              <a
                href="/"
                className="flex items-center py-2 px-4 text-sm hover:bg-gray-100"
                ><svg
                  className="mr-2 w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                My likes</a>
            </li>
            <li>
              <a
                href="/"
                className="flex items-center py-2 px-4 text-sm hover:bg-gray-100"
                ><svg
                  className="mr-2 w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
                  ></path>
                </svg>
                Collections</a>
            </li>
            <li>
              <a
                href="/"
                className="flex justify-between items-center py-2 px-4 text-sm hover:bg-gray-100"
              >
                <span className="flex items-center">
                  <svg
                    aria-hidden="true"
                    className="mr-2 w-5 h-5 text-primary-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  Pro version
                </span>
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </li>
          </ul>
          <ul
            className="py-1 text-gray-700 "
            aria-labelledby="dropdown"
          >
            <li>
              <div
                className="block py-2 px-4 text-sm hover:bg-gray-100 cursor-pointer"
                onClick={() => signOut(auth)}
              >
                Ausloggen
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  )
}

export default TopBar