// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getStorage  } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDzV6gbfw6w9oDsnygHXKcgyI-ihf10Qn4",
  authDomain: "weedlink-23.firebaseapp.com",
  projectId: "weedlink-23",
  storageBucket: "weedlink-23.appspot.com",
  messagingSenderId: "991484310951",
  appId: "1:991484310951:web:98793d2065b9d65bcc3282",
  measurementId: "G-YMT9JQ7VYL"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
