import React, { useState } from 'react'
import TopBar from './topBar/TopBar'
import SideBar from './sideBar/SideBar'
import { Outlet } from 'react-router-dom'

function Layout({ user, clubData} : any) {
  const [sideBarOpen, setSideBarOpen] = useState(false)
  return (
    <>
        <TopBar user={user} setSideBarOpen={setSideBarOpen} sideBarOpen={sideBarOpen} />
        <SideBar clubData={clubData} sideBarOpen={sideBarOpen} />
        <main className="p-4 h-screen md:ml-64 pt-20 flex flex-col overflow-y-scroll">
            <Outlet />
        </main>
    </>
  )
}

export default Layout