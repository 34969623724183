import { User } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { FiMapPin } from 'react-icons/fi'
import { ClubType } from '../DashboardSkeleton'
import { addDoc, collection,  serverTimestamp } from 'firebase/firestore'
import { firestore, storage } from '../../firebase/Firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { geohashForLocation } from 'geofire-common'

type PropsType = {
    user: User,
    clubData: ClubType
}

function CreatePublicProfile({ user, clubData} : PropsType) {

    const [queriedLocationData, setQueriedLocationData] = useState<{ adress: string, lat: number, lng: number, city: string, state: string, formattedAdress: string }>()
    const [autoCompleteActive, setAutoCompleteActive] = useState(true)
    const [file, setFile] = useState<undefined | File>()
    const [nameInput, setNameInput] = useState("")
    const [urlInput, setUrlInput] = useState("")
    const [uploadLoading, setUploadLoading] = useState(false)
    const [results, setResults] = useState<any>([])
    const [text, setText] = useState("")




    const autocomplete = new window.google.maps.places.AutocompleteService();
    const placeService = new window.google.maps.places.PlacesService(document.createElement("div"));

    useEffect(() => {
        if(text && autoCompleteActive) {
            autocomplete.getPlacePredictions({input: text, componentRestrictions: {country: 'DE'}}, async (pred) => {
                const qresults = pred || [];
                setResults(qresults);
            });
        }
    }, [text, autoCompleteActive])

    const handleLocationClick = (p: google.maps.places.AutocompletePrediction) => {
    placeService.getDetails({ placeId: p.place_id, language: 'de'}, r => {
        const placeResult = r
        if(!placeResult) return
        if(!placeResult.address_components) return

        // Extrahiere den Namen der Stadt
        const city = placeResult.address_components.find(component =>
        component.types.includes("locality")
        )?.long_name;

        // Extrahiere den Namen des Bundeslandes
        const state = placeResult.address_components.find(component =>
        component.types.includes("administrative_area_level_1")
        )?.long_name;

        console.log("Stadt:", city);
        console.log("Bundesland:", state);
        
        setText("")
        setQueriedLocationData({
            adress: placeResult.formatted_address || '',
            lat: r?.geometry?.location?.lat() ?? 0,
            lng: r?.geometry?.location?.lng() ?? 0,
            city: city || '',
            state: state || '',
            formattedAdress: placeResult.formatted_address || ''
        })
        setAutoCompleteActive(false)
        setText(placeResult.formatted_address ||'')

        })
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if(!e.target.files) return
        const selectedFile = e.target.files[0];
        setFile(selectedFile);  
    }

    const handleSubmit = async (e : React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setUploadLoading(true)
        console.log(file, queriedLocationData, nameInput, urlInput, clubData)
        if(!file) {alert("Bitte Lade ein Logo hoch!"); setUploadLoading(false);  return}
        if(!queriedLocationData) {alert("Bitte wähle einen Standort aus!"); setUploadLoading(false);  return}
        if(!nameInput) {alert("Bitte gib einen Namen ein"); setUploadLoading(false); return}
        if(!urlInput) {alert("Bitte gib eine URl an"); setUploadLoading(false); return}
        if(!clubData) {alert("Es ist ein Fehler aufgetreten"); setUploadLoading(false); return}

        const lat = queriedLocationData.lat;
        const lng = queriedLocationData.lng;
        const hash = geohashForLocation([lat, lng]);
    
        const storageRef = ref(storage, `uploaded_logos/${ nameInput + '-' + file.name + '-' + new Date().getTime() }`)
        await uploadBytes(storageRef, file)
        const downloadUrl = await getDownloadURL(storageRef)
        await addDoc(collection(firestore, 'cscs'), {
            name: nameInput,
            createdAt: serverTimestamp(),
            logoUrl: downloadUrl,
            mainUrl: urlInput,
            linkedClubId: clubData.id,
            g: {
                lat: lat,
                lng: lng,
                hash: hash,
                city: queriedLocationData.city,
                region: queriedLocationData.state,
                formattedAdress: queriedLocationData.formattedAdress
            }
        })
        setUploadLoading(false)

    }


  return (
    <>
    <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
        <h2 className="mb-4 text-xl font-bold ">Füge deinen CSC zu unserer Plattform hinzu</h2>
        <form action="#" onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <div className='relative sm:col-span-2'>
                    <input
                        value={text}
                        onChange={e => (setText(e.target.value))} 
                        onFocus={() => setAutoCompleteActive(true)}
                        className="bg-gray-50 border pl-10 h-11 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        placeholder='Standort eingeben'
                    />
                    <FiMapPin className='absolute left-4 top-1/2 -translate-y-1/2' />
                    { autoCompleteActive && <div className='bg-white absolute top-12 left-0 right-0 shadow rounded-xl'>
                        {results && results.map((r : google.maps.places.AutocompletePrediction) => (
                            <div onClick={() => handleLocationClick(r)} className='flex items-center p-4 hover:bg-gray-100 cursor-pointer'>
                                <div className='bg-gray-200 p-2 rounded-md'>
                                    <FiMapPin />
                                </div>
                                <p className='ml-2'>{r.description}</p>
                            </div>
                        ))}  
                    </div>}
                </div>
                <div className="sm:col-span-2">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 "> Name</label>
                    <input 
                        type="text"
                        value={nameInput}
                        onChange={e => setNameInput(e.target.value)}
                        name="name"
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " 
                        placeholder="Name"
                    />
                </div>
                <div className="sm:col-span-2">
                    <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900 "> URL</label>
                    <input 
                        type="url"
                        value={urlInput}
                        onChange={e => setUrlInput(e.target.value)}
                        id="name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 " 
                        placeholder="URL"
                    />
                    <p className='mt-1 text-sm text-gray-500'>Format: https://domain.de</p>
                </div>
                
                
                <div className='w-full'>
                <label className="block mb-2 text-sm font-medium text-gray-900 " htmlFor="file_input">Logo hochladen</label>
                <input accept=".png,.jpg,.webp,.svg" onChange={handleFileChange} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none " aria-describedby="file_input_help" id="file_input" type="file" />
                <p className="mt-1 text-sm text-gray-500 " id="file_input_help">SVG, PNG, JPG, WEBP</p>
                </div>
            </div>
            <button type="submit" disabled={uploadLoading} className="disabled:opacity-80 flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-slate-700 rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800">
                    { uploadLoading &&
                    <svg aria-hidden="true" className="w-6 h-6 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-emerald-400" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    }
                    <p>
                        Jetzt erstellen
                    </p>
            </button>
        </form>
    </div>
    </>
  )
}

export default CreatePublicProfile