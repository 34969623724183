import React, { useEffect, useState } from 'react';
import { User, createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { firebaseApp } from './firebase/Firebase';
import DashboardSkeleton from './dashboardSkeleton/DashboardSkeleton';
//@ts-ignore
import Logo from './logo-rose.svg'

function App() {
  const [userData, setUserData] = useState<User | null >(null)
  const [mailInput, setMailInput] = useState("")
  const [passwordInput, setPasswordInput] = useState("")
  const [errorCode, setErrorCode] = useState<string | null>(null)
  const [authType, setAuthType] = useState(2)
  const a = firebaseApp


  const auth = getAuth();
  
  useEffect(() => {
    onAuthStateChanged(auth, user => {
      setUserData(user)
    }) 
  }, [auth])
  

  const handleRegister = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    createUserWithEmailAndPassword(auth, mailInput, passwordInput)
    .then(() => console.log("registriert"))
    .catch(e => setErrorCode(e.code))
  }

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    signInWithEmailAndPassword(auth,mailInput,passwordInput)
    .then(() => "Eingeloggt")
    .catch(e => setErrorCode(e.code))
  }

  const handleLogout = () => {
    signOut(auth)
    .then(() => console.log("ausgeloggt"))
    .catch(e => setErrorCode(e.code))
  }

  const renderRegistration = () => {
    return (
      <section className="bg-gray-50 h-screen">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
              <img alt="test" className="w-8 h-8 mr-2" src={Logo} />
              weedlink
          </a>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                      Account erstellen & CSC eintragen
                  </h1>
                  <p className='text-gray-500 text-sm'>
                    Nach der Account-Erstellung kannst du deinen CSC bei uns eintragen, Mitglieder verwalten und vieles mehr
                  </p>
                  <form onSubmit={handleRegister} className="space-y-4 md:space-y-6" action="#">
                      <div>
                          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Deine E-Mail</label>
                          <input value={mailInput} onChange={e => setMailInput(e.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 " placeholder="name@company.com" required />
                      </div>
                      <div>
                          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Passwort</label>
                          <input value={passwordInput} onChange={e => setPasswordInput(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 " required />
                      </div>
                      <button type="submit" className="w-full text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                        Account erstellen
                      </button>
                      {errorCode && <p className=' text-red-800'>{authErrors[errorCode?.replace('auth/', '') || ''] || errorCode}</p>}
                      <p className="text-sm font-light text-gray-500">
                          Du hast bereits einen Account? <span onClick={() => setAuthType(1)} className="cursor-pointer inline font-medium text-slate-600 hover:underline ">Jetzt einloggen</span>
                      </p>
                  </form>
              </div>
          </div>
      </div>
      </section> 
    )
  }

  const renderLogin = () => {
    return (
      <section className="bg-gray-50 ">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
              <img alt="test" className="w-8 h-8 mr-2" src={Logo} />
              weedlink   
          </a>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0">
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                      Einloggen
                  </h1>
                  <form  onSubmit={handleLogin} className="space-y-4 md:space-y-6" action="#">
                      <div>
                          <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Deine Mail-Adresse</label>
                          <input value={mailInput} onChange={e => setMailInput(e.target.value)} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 " placeholder="name@company.com" required />
                      </div>
                      <div>
                          <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 ">Passwort</label>
                          <input value={passwordInput} onChange={e => setPasswordInput(e.target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-slate-600 focus:border-slate-600 block w-full p-2.5 " required />
                      </div>
                      <button type="submit" className="w-full text-white bg-slate-600 hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-slate-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                       Einloggen
                      </button>
                      {errorCode && <p className=' text-red-800'>{authErrors[errorCode?.replace('auth/', '') || ''] || errorCode}</p>}
                      <p className="text-sm font-light text-gray-500 ">
                          Noch keinen Account? <span onClick={() => setAuthType(2)} className="cursor-pointer font-medium text-slate-600 hover:underline ">Registriere dich jetzt</span>
                      </p>
                  </form>
              </div>
          </div>
      </div>
      </section> 
    )
  }

  const renderAuthScreen = () => {
    return (
      <>
        {authType === 1 ?  renderLogin() : renderRegistration()}
      </>
     
    )
  }

  /**
   * 
   *  <div className='flex flex-col justify-center items-center container mx-auto mt-8'>
       {false &&
       <>
        <input className='border p-2 border-gray-900' placeholder='Mail' value={mailInput} onChange={e => setMailInput(e.target.value)} />
        <input className='my-4 p-2 border border-gray-900' placeholder='Passwort' type="password" value={passwordInput} onChange={e => setPasswordInput(e.target.value)} />
        <button className='bg-slate-200 m-2 p-2 cursor-pointer' onClick={handleRegister}>Create User</button>
        <button className='bg-slate-200 m-2 p-2 cursor-pointer' onClick={handleLogin}>Login</button>
        <button className='bg-slate-200 m-2 p-2 cursor-pointer' onClick={handleLogout}>Logout</button>
        {errorCode && <p className=' text-red-800'>{authErrors[errorCode?.replace('auth/', '') || ''] || errorCode}</p>}
       </>
       }
        
      </div>
   */

  return (
    <>
      { !userData ? 
        renderAuthScreen()
        :
        <>
        <DashboardSkeleton user={userData} />
        </>
      }
    </>
  );
}

export default App;



export const authErrors: any = {
  'admin-restricted-operation': 'Dieser Vorgang ist ausschließlich für Administratoren beschränkt.',
  'argument-error': '',
  'app-not-authorized': 'Diese App, die durch die Domain identifiziert wird, wo sie gehostet wird, ist nicht berechtigt, Firebase Authentifizierung mit dem bereitgestellten API-Schlüssel zu verwenden. Überprüfen Sie Ihre Schlüsselkonfiguration im Google API-Konsolenbereich.',
  'app-not-installed': 'Die angeforderte mobile Anwendung, die dem angegebenen Identifikator (Android-Paketname oder iOS-Bundle-ID) entspricht, ist auf diesem Gerät nicht installiert.',
  'captcha-check-failed': 'Das bereitgestellte reCAPTCHA-Antworttoken ist entweder ungültig, abgelaufen, bereits verwendet oder die damit verbundene Domain entspricht nicht der Liste der whitelisted-Domains.',
  'code-expired': 'Der SMS-Code ist abgelaufen. Bitte senden Sie den Bestätigungscode erneut, um es erneut zu versuchen.',
  'cordova-not-ready': 'Das Cordova-Framework ist noch nicht bereit.',
  'cors-unsupported': 'Dieser Browser wird nicht unterstützt.',
  'credential-already-in-use': 'Dieses Anmeldeinformationen sind bereits mit einem anderen Benutzerkonto verknüpft.',
  'custom-token-mismatch': 'Das benutzerdefinierte Token entspricht einem anderen Zielpublikum.',
  'requires-recent-login': 'Dieser Vorgang ist sensibel und erfordert eine aktuelle Authentifizierung. Melden Sie sich erneut an, bevor Sie diese Anfrage erneut versuchen.',
  'dynamic-link-not-activated': 'Bitte aktivieren Sie Dynamic Links im Firebase Console und stimmen Sie den Bedingungen und Konditionen zu.',
  'email-change-needs-verification': 'Multi-Faktor-Benutzer müssen immer eine bestätigte E-Mail haben.',
  'email-already-in-use': 'Die E-Mail-Adresse wird bereits von einem anderen Konto verwendet.',
  'expired-action-code': 'Der Aktionscode ist abgelaufen.',
  'cancelled-popup-request': 'Dieser Vorgang wurde aufgrund eines anderen Konflikts, der geöffnet wurde, abgebrochen.',
  'internal-error': 'Ein interner Fehler ist aufgetreten.',
  'invalid-app-credential': 'Die Telefonverifizierungsanforderung enthält einen ungültigen Anwendungsüberprüfer. Der reCAPTCHA-Token-Antwort ist entweder ungültig oder abgelaufen.',
  'invalid-app-id': 'Die mobile App-Kennung ist für das aktuelle Projekt nicht registriert.',
  'invalid-user-token': 'Diese Benutzerberechtigung ist für dieses Projekt nicht gültig. Dies kann passieren, wenn das Token des Benutzers manipuliert wurde oder wenn der Benutzer nicht für das mit diesem API-Schlüssel verbundene Projekt registriert ist.',
  'invalid-auth-event': 'Ein interner Fehler ist aufgetreten.',
  'invalid-verification-code': 'Der zur Erstellung der Telefonauth-Berechtigung verwendete SMS-Verifizierungscode ist ungültig. Bitte senden Sie den Verifizierungscode-SMS erneut und stellen Sie sicher, dass Sie den vom Benutzer bereitgestellten Verifizierungscode verwenden.',
  'invalid-continue-uri': 'Die im Anforderungsprozess bereitgestellte continue URL ist ungültig.',
  'invalid-cordova-configuration': 'Zur Aktivierung von OAuth-Anmeldung müssen die folgenden Cordova-Plugins installiert sein: cordova-plugin-buildinfo, cordova-universal-links-plugin, cordova-plugin-browsertab, cordova-plugin-inappbrowser und cordova-plugin-customurlscheme.',
  'invalid-custom-token': 'Das Format des benutzerdefinierten Tokens ist falsch. Bitte überprüfen Sie die Dokumentation.',
  'invalid-dynamic-link-domain': 'Die bereitgestellte dynamische Link-Domain ist für das aktuelle Projekt nicht konfiguriert oder autorisiert.',
  'invalid-email': 'Die E-Mail-Adresse hat ein falsches Format.',
  'invalid-api-key': 'Ihr API-Schlüssel ist ungültig, bitte überprüfen Sie, ob Sie ihn richtig kopiert haben.',
  'invalid-cert-hash': 'Der bereitgestellte SHA-1-Zertifikats-Hash ist ungültig.',
  'invalid-credential': 'Die bereitgestellte Auth-Berechtigung ist fehlerhaft oder abgelaufen.',
  'invalid-message-payload': 'Das zu dieser Aktion gehörende E-Mail-Template enthält ungültige Zeichen in seiner Nachricht. Bitte beheben Sie das Problem im Abschnitt "Auth E-Mail-Vorlagen" in der Firebase-Konsole.',
  'invalid-multi-factor-session': 'Die Anfrage enthält keinen gültigen Beweis für eine erfolgreiche Anmeldung mit dem ersten Faktor.',
  'invalid-oauth-provider': 'EmailAuthProvider wird für diesen Vorgang nicht unterstützt. Dieser Vorgang unterstützt nur OAuth-Provider.',
  'invalid-oauth-client-id': 'Die bereitgestellte OAuth-Client-ID ist entweder ungültig oder entspricht nicht dem angegebenen API-Schlüssel.',
  'unauthorized-domain': 'Diese Domain ist für OAuth-Vorgänge für Ihr Firebase-Projekt nicht berechtigt. Bearbeiten Sie die Liste der berechtigten Domains im Firebase-Konsolenbereich.',
  'invalid-action-code': 'Der Aktionscode ist ungültig. Dies kann passieren, wenn der Code falsch formatiert, abgelaufen ist oder bereits verwendet wurde.',
  'wrong-password': 'Das Passwort ist ungültig oder der Benutzer hat kein Passwort.',
  'invalid-persistence-type': 'Der angegebene Persistenztyp ist ungültig. Es kann nur lokal, Sitzung oder keiner sein.',
  'invalid-phone-number': 'Das Format der bereitgestellten Telefonnummer ist falsch. Bitte geben Sie die Telefonnummer in einem Format ein, das in das E.164-Format geparst werden kann. E.164-Telefonnummern sind in der Form [+][Ländercode][Teilnehmernummer inklusive Vorwahl] geschrieben.',
  'invalid-provider-id': 'Die angegebene Provider-ID ist ungültig.',
  'invalid-recipient-email': 'Die zu dieser Aktion gehörende E-Mail konnte nicht gesendet werden, da die angegebene Empfänger-E-Mail-Adresse ungültig ist.',
  'invalid-sender': 'Die E-Mail-Vorlage für diese Aktion enthält eine ungültige E-Mail-Adresse oder einen ungültigen Namen des Absenders. Bitte korrigieren Sie dies im Bereich "Auth-E-Mail-Vorlagen" in der Firebase-Konsole.',
  'invalid-verification-id': 'Die zur Erstellung des Telefonauth-Anmeldeinformationen verwendete Verifizierungskennung ist ungültig.',
  'invalid-tenant-id': 'Die Tenant-ID der Auth-Instanz ist ungültig.',
  'multi-factor-info-not-found': 'Der Benutzer hat keine zweite Faktor-Information, die dem angegebenen Identifikator entspricht.',
  'multi-factor-auth-required': 'Zur Abschluss der Anmeldung ist ein Nachweis des Besitzes eines zweiten Faktors erforderlich.',
  'missing-android-pkg-name': 'Wenn die Android-App installiert werden muss, muss ein Android-Paketname angegeben werden.',
  'auth-domain-config-required': 'Stellen Sie sicher, dass Sie beim Aufruf von firebase.initializeApp() authDomain einschließen, indem Sie den Anweisungen in der Firebase-Konsole folgen.',
  'missing-app-credential': 'Die Anfrage für die Telefonüberprüfung enthält keine Verifizierungsanforderung. Ein reCAPTCHA-Antworttoken muss bereitgestellt werden.',
  'missing-verification-code': 'Das Anmeldeinformationen-Credential für das Telefon wurde mit einem leeren SMS-Verifizierungscode erstellt.',
  'missing-continue-uri': 'In der Anfrage muss eine Fortführungs-URL bereitgestellt werden.',
  'missing-iframe-start': 'Ein interner Fehler ist aufgetreten.',
  'missing-ios-bundle-id': 'Eine iOS Bundle ID muss bereitgestellt werden, wenn eine App Store ID bereitgestellt wird.',
  'missing-multi-factor-info': 'Es wurde keine Informationen für einen zweiten Faktor bereitgestellt.',
  'missing-multi-factor-session': 'In der Anfrage fehlt der Nachweis für eine erfolgreiche Anmeldung mit dem ersten Faktor.',
  'missing-or-invalid-nonce': 'Die Anfrage enthält keine gültige Nonce. Dies kann passieren, wenn der SHA-256-Hash der bereitgestellten Rohdaten-Nonce nicht mit der gehashten Nonce im ID-Token-Payload übereinstimmt.',
  'missing-phone-number': 'Um Überprüfungscodes zu senden, geben Sie eine Telefonnummer für den Empfänger an.',
  'missing-verification-id': 'Das Anmeldeinformationen-Credential für das Telefon wurde mit einer leeren Überprüfungs-ID erstellt.',
  'app-deleted': 'Diese Instanz von FirebaseApp wurde gelöscht.',
  'account-exists-with-different-credential': 'Es existiert bereits ein Konto mit der gleichen E-Mail-Adresse, aber unterschiedlichen Anmeldeinformationen. Melden Sie sich mit einem Anbieter an, der mit dieser E-Mail-Adresse verknüpft ist.',
  'network-request-failed': 'Ein Netzwerkfehler ist aufgetreten (wie Zeitüberschreitung, unterbrochene Verbindung oder nicht erreichbarer Host).',
  'no-auth-event': 'Ein interner Fehler ist aufgetreten.',
  'no-such-provider': 'Dem Benutzer ist kein Konto mit dem angegebenen Anbieter verknüpft.',
  'null-user': 'Es wurde ein Null-Benutzerobjekt als Argument für eine Operation bereitgestellt, die ein nicht-nulles Benutzerobjekt erfordert.',
  'operation-not-allowed': 'Der angegebene Anmelde-Provider ist für dieses Firebase-Projekt deaktiviert. Aktivieren Sie es im Firebase-Konsolenfenster unter dem Reiter "Anmelde-Methoden" des Abschnitts "Auth".',
  'operation-not-supported-in-this-environment': 'Diese Operation wird im Umfeld, in dem diese Anwendung ausgeführt wird, nicht unterstützt. "location.protocol" muss http, https oder chrome-extension sein und Web-Speicher muss aktiviert sein.',
  'popup-blocked': 'Es konnte keine Verbindung mit dem Popup hergestellt werden. Es könnte vom Browser blockiert worden sein.',
  'popup-closed-by-user': 'Das Popup wurde vom Benutzer geschlossen, bevor die Operation abgeschlossen wurde.',
  'provider-already-linked': 'Der Benutzer kann nur an eine Identität für den angegebenen Anbieter verknüpft werden.',
  'quota-exceeded': 'Das Quota des Projekts für diese Operation ist überschritten.',
  'redirect-cancelled-by-user': 'Der Umleitungsvorgang wurde vom Benutzer vor der Fertigstellung abgebrochen.',
  'redirect-operation-pending': 'Eine Umleitungsanmeldeoperation ist bereits ausstehend.',
  'rejected-credential': 'Die Anfrage enthält fehlerhafte oder nicht übereinstimmende Anmeldeinformationen.',
  'second-factor-already-in-use': 'Der Zweitfaktor ist bereits für diesen Account registriert.',
  'maximum-second-factor-count-exceeded': 'Die maximale Anzahl erlaubter Zweitfaktoren für einen Benutzer ist überschritten.',
  'tenant-id-mismatch': 'Die bereitgestellte Miet-ID entspricht nicht der Miet-ID des Auth-Instanz',
  'timeout': 'Der Vorgang ist abgelaufen.',
  'user-token-expired': 'Das Anmeldezeugnis des Benutzers ist nicht mehr gültig. Der Benutzer muss sich erneut anmelden.',
  'too-many-requests': 'Wir haben aufgrund ungewöhnlicher Aktivitäten alle Anfragen von diesem Gerät blockiert. Versuchen Sie es später erneut.',
  'unauthorized-continue-uri': 'Die Domain der Weiterleitungs-URL ist nicht auf der Whitelist. Bitte fügen Sie die Domain im Firebase-Konsolen hinzu.',
  'unsupported-first-factor': 'Zur Registrierung eines Zweitfaktors oder Anmeldung mit einem Mehrfaktor-Konto ist eine Anmeldung mit einem unterstützten ersten Faktor erforderlich.',
  'unsupported-persistence-type': 'Die aktuelle Umgebung unterstützt den angegebenen Speichertyp nicht.',
  'unsupported-tenant-operation': 'Dieser Vorgang wird in einem Multi-Mieter-Kontext nicht unterstützt.',
  'unverified-email': 'Der Vorgang erfordert eine bestätigte E-Mail-Adresse.',
  'user-cancelled': 'Der Benutzer hat Ihrer Anwendung die angeforderten Berechtigungen nicht erteilt.',
  'user-not-found': 'Es gibt keinen Benutzer mit dieser Identifikation. Der Benutzer könnte gelöscht worden sein.',
  'user-disabled': 'Das Benutzerkonto wurde von einem Administrator deaktiviert.',
  'user-mismatch': 'Die bereitgestellten Anmeldeinformationen entsprechen nicht dem zuvor angemeldeten Benutzer.',
  'user-signed-out': '',
  'weak-password': 'Das Passwort muss mindestens 6 Zeichen lang sein.',
  'web-storage-unsupported': 'Dieser Browser wird nicht unterstützt oder 3. Partei-Cookies und Daten könnten deaktiviert sein.',
};