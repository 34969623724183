import React, { useEffect, useState } from 'react'
import TopBar from './topBar/TopBar'
import SideBar from './sideBar/SideBar'
import { User } from 'firebase/auth'
import {  Timestamp, collection, doc, getDoc, increment, onSnapshot, query, updateDoc, where, } from 'firebase/firestore'
import { firestore } from '../firebase/Firebase'
import AddClub from './AddClub'
import CreatePublicProfile from './createPublicProfile/CreatePublicProfile'

// @ts-ignore
import emptyState from './search.png'
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom'
import Layout from './Layout'

type PropsType = {
    user: User
}

export type MemberShipDocumentType = {
    id: string,
    clubs: {
        [clubId: string] : string,
    }
}

export type ClubType = {
    id: string,
    userRole: string,
    name: string,
    earlyAccessPosition?: number
}

export type PublicProfileType = {
    createdAt: Timestamp,
    g: {
        city: string,
        hash: string,
        lat: number,
        lng: number,
        region: string,
        formattedAdress?: string,
    }
    logoUrl: string,
    mainUrl: string,
    name: string,
    linkedClubId?: string,
}

export type ClubStateType = ClubType | undefined | null


function DashboardSkeleton({ user} : PropsType) {
    const [fetchedMembershipData, setFetchedMembershipData] = useState<{clubId: string, role: string} | undefined | null>()
    const [clubData, setClubData] = useState<ClubStateType>()
    const [publicProfileData, setPublicProfileData] = useState<PublicProfileType | undefined >()
    const [createOpen, setCreateOpen] = useState(false)
    

    useEffect(() => {
      const d = doc(firestore, 'memberships', `${user.email}`)
      onSnapshot(d, doc => {
        if(doc.exists()) {
            const data : MemberShipDocumentType = Object(doc.data())
            if(!data || !data.clubs) {
                setFetchedMembershipData(null)
                return
            }
            
            const clubList = Object.entries(data.clubs).map(([clubId, clubRole]) =>  ({ clubId : clubId, role: clubRole}))
            if(clubList.length > 1) alert("Achtung, Für diesen Account ist mehr als eine Mitgliedschaft hinterlegt. Es wird automatisch der erste Club in der Liste genommen. Falls das ein Fehler ist, kontaktiere einen Admin")
            setFetchedMembershipData(clubList[0])
        } else setFetchedMembershipData(null)
      })
    }, [user.email])

    useEffect(() => {
        if(fetchedMembershipData?.clubId && fetchedMembershipData.role) {
            const d = doc(firestore, 'clubs', fetchedMembershipData.clubId)
            onSnapshot(d, doc => {
                if (doc.exists()) {
                    setClubData({...doc.data(), id: doc.id, userRole: fetchedMembershipData.role} as ClubType) 
                } else setClubData(undefined)
            })
        } else setClubData(undefined)
    }, [fetchedMembershipData])

    useEffect(() => {
        if(clubData?.id) {
            const q = query(collection(firestore, 'cscs'), where('linkedClubId', '==', clubData.id))
            onSnapshot(q, docs => {
                const fetchedProfiles : PublicProfileType[] = []
                docs.forEach(doc => {
                    fetchedProfiles.push({...doc.data() as PublicProfileType})
                })
                if(fetchedProfiles.length > 0) {
                    setPublicProfileData(fetchedProfiles[0])
                } else setPublicProfileData(undefined)
            })
        }
    }, [clubData])
    
    const enterEarlyAccess =  async () => {
        const docRef = doc(firestore, 'earlyAccess', 'queue')
        const docR = await getDoc(docRef)
        if(docR.exists() && clubData?.id) {
            await updateDoc(doc(firestore, 'clubs', clubData.id), {
                earlyAccessPosition: docR.data().length + 1,
            })
            await updateDoc(docRef, {
                length: increment(1)
            })
        }
    }

    const renderPopup = () => {
        if( fetchedMembershipData === undefined ) {
            return (
                <>
                    <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    Lade deine Mitgliedschaften
                </>
            )
        }
        if( fetchedMembershipData == null ) {
            return <AddClub user={user} />
        }
        if( clubData == null ) {
            return (
                <>
                    Beim Laden des Clubs ist ein Fehler aufgetreten. Bitte versuche es erneut oder kontaktiere einen Admin.
                </>
            )
        }
        return (
            <>
                <svg aria-hidden="true" className="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                Lade Club Daten
            </>
        )
    }

  return (
    <>
    <div className="antialiased bg-gray-50">
        { clubData === undefined &&
            <div className='absolute inset-0 bg-slate-800/80 z-[100] flex justify-center items-center'>
                <div className='p-8 border bg-white rounded-xl max-w-2xl mx-2'>
                    {renderPopup()}
                </div>
            </div>
        }
        { clubData &&
            <>
            <BrowserRouter>
                <Routes>
                    <Route path='*' element={<Layout user={user} clubData={clubData} />}>
                        <Route path="" element={
                                    <>
                                        { !publicProfileData ?
                                    createOpen ?
                                        <CreatePublicProfile clubData={clubData} user={user} />
                                    :
                                        <>
                                            <div className='h-full flex flex-col items-center justify-center'>
                                                <p className='text-3xl mb-2 font-bold text-center'>Noch kein öffentlicher Eintrag</p>
                                                <p className='text-gray-600 max-w-lg text-center mb-8'>
                                                    Damit dein CSC auf unserer Plattform gefunden werden kann, musst du hier einen öffentliches Eintrag erstellen.
                                                </p>
                                                <div
                                                    className="flex flex-col p-4 rounded-lg w-full max-w-xl items-center justify-center"
                                                >
                                                    <img className='w-6/12' src={emptyState} alt="empty state" />
                                                    <button onClick={() => setCreateOpen(true)} className="block  items-center px-6 py-4 mt-4 sm:mt-6 text-sm disabled:opacity-70 font-bold text-center text-white bg-slate-700 rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800">
                                                        Jetzt Eintrag erstellen
                                                    </button>
                                                </div>    
                                            </div>
                                        </>
                                    : null
                                }
                                {publicProfileData && 
                                    <div className='flex flex-col self-center justify-self-center items-center justify-center'>
                                        <p className='text-3xl mb-2 font-bold'>Öffentliches Club-Profil</p>
                                        <p className='text-gray-600 max-w-lg text-center mb-8'>Diesen Eintrag sehen Nutzer auf weedlink, wenn Sie nach einem Cannabis Social Club in Ihrer Nähe suchen</p>
                                        <div
                                            className="flex flex-col p-4 border-2 border-dashed border-gray-300 rounded-lg w-full max-w-xl"
                                        >

                                            <div className={'relative z-0 flex justify-center items-center aspect-[4/3] rounded-xl w-full bg-emerald-400'}>
                                                <div className='absolute aspect-square bg-slate-900 rounded-lg w-7/12 translate-x-4 md:translate-x-2 translate-y-4 md:translate-y-2 z-0'></div>
                                                <div className=' aspect-square bg-white rounded-lg w-7/12 z-10 flex justify-center items-center'>
                                                <img src={publicProfileData.logoUrl} alt="logo vom csc" className='w-10/12' />
                                                </div>
                                            </div>
                                            <p className=" text-gray-900 mt-2 font-semibold text-lg">{publicProfileData.name}</p>
                                            <p className=" -mt-1 text-gray-400 text">{publicProfileData.g.city}, {publicProfileData.g.region}</p>
                                            <p className='text-gray-800 mt-2'>Website URL</p>
                                            <p className=' text-gray-400'>{publicProfileData.mainUrl}</p>

                                            <div className='self-end flex flex-col mt-8'>
                                            <button disabled className="block self-end items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm disabled:opacity-70 font-medium text-center text-white bg-slate-700 rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800">
                                                Daten bearbeiten
                                            </button>
                                            <p className='text-right text-xs mt-1 text-gray-600'>In 1-2 Tagen Verfügbar</p>
                                            </div>
                                        </div>

                                    </div>
                                }
                                    </>
                            } />
                        <Route path='*' element={
                            <div>
                                <div className="grid grid-cols-1 gap-4 mb-4">
                                    <div
                                        className="flex flex-col items-center justify-center border-2 border-dashed rounded-lg border-gray-300 h-68 md:h-96"
                                    >
                                        <p className='text-3xl font-bold'>Deinen CSC Verwalten</p>
                                        <p className='text-center w-8/12 text-gray-600'>
                                            Wir bieten Cannabis Social Clubs eine All-in-one Plattform, die die Vereinsverwaltung vereinfacht und automatisiert.
                                            Von Beitragsverwaltung, über Buchhaltung, bis hin zu Abgaben mit digitalen Zahlungen Lösen wir alle Probleme und Anforderungen von CSCSs und das <b>kostenlos</b>!
                                        </p>
                                        {!clubData.earlyAccessPosition ?
                                        <button onClick={enterEarlyAccess} className="block font-bold self-center items-center px-6 py-4 mt-4 sm:mt-6 text-sm disabled:opacity-70 text-center text-white bg-emerald-700 rounded-lg focus:ring-4 focus:ring-primary-200  hover:bg-primary-800">
                                            Jetzt Early Access erhalten
                                        </button>
                                        :
                                        <>
                                        <p className='text-center mt-6 bg-emerald-100 rounded-xl p-4'>Du bist Platz <span className='text-emerald-600 font-bold'>#{clubData.earlyAccessPosition}</span> auf der Early-Acess-Liste. <br />Wir kontaktieren dich, sobald neue Features für dich zur Verfügung stehen.</p>
                                        </>
                                        }
                                    </div>
                                </div>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
                                    <div
                                    className="border-2 border-dashed border-gray-300 rounded-lg h-32 md:h-64"
                                    ></div>
                                    <div
                                    className="border-2 border-dashed rounded-lg border-gray-300 h-32 md:h-64"
                                    ></div>
                                    <div
                                    className="border-2 border-dashed rounded-lg border-gray-300 h-32 md:h-64"
                                    ></div>
                                    <div
                                    className="border-2 border-dashed rounded-lg border-gray-300 h-32 md:h-64"
                                    ></div>
                                </div>
                                <div className="grid grid-cols-2 gap-4 mb-4">
                                    <div
                                        className="border-2 border-dashed rounded-lg border-gray-300 h-32 md:h-64"
                                    ></div>
                                    <div
                                        className="border-2 border-dashed rounded-lg border-gray-300 h-32 md:h-64"
                                    ></div>
                                </div>
                            </div>
                        } />
                    </Route>
                </Routes>
            </BrowserRouter>
              
            </>
        }
    </div>
    </>
  )
}

export default DashboardSkeleton

            