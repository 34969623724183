import React, { useState } from 'react'
import { ClubStateType, ClubType } from '../DashboardSkeleton'
import { Link, useLocation } from 'react-router-dom'
import { useLoadScript } from '@react-google-maps/api'

type PropsType = {
  clubData: ClubType
  sideBarOpen: boolean
}

function SideBar({ clubData, sideBarOpen } : PropsType) {
  const location = useLocation()
  const [toggleMemberships, setToggleMemberships] = useState(false)

  return (
    <aside
    className={(sideBarOpen ? 'translate-x-0': '') +  " fixed top-0 left-0 z-40 w-64 h-screen pt-14 transition-transform -translate-x-full bg-white border-r border-gray-200 md:translate-x-0"}
    aria-label="Sidenav"
    id="drawer-navigation"
  >
    <div className="overflow-y-auto py-5 px-3 h-full bg-white ">
      <div className='relative'>
        <div className='rounded-xl bg-emerald-200 w-full relative z-10'>
          <p className='pb-5 pt-5 text-center font-xl font-bold'>
            {clubData.name}
          </p>
        </div>
        <div className='absolute inset-0 bg-slate-700 rounded-xl z-0 translate-x-1 translate-y-1'></div>
      </div>
      <ul className="space-y-2">
        <li>
          <Link
            to="/"
            className={(location.pathname === '/' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " mt-6 flex items-center p-2 text-base font-medium text-gray-900 rounded-lg  hover:bg-gray-100 group"}
          >
            <svg
                aria-hidden="true"
                className={"flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900"}
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            <span className="ml-3">Öffentliches Profil</span>
          </Link>
        </li>
        {true && 
          <li>
          <button
            type="button"
            className="mt-8 border-t border-gray-200  flex items-center p-2 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100  "
            aria-controls="dropdown-pages"
            data-collapse-toggle="dropdown-pages"
            onClick={() => setToggleMemberships(!toggleMemberships)}
          >
            <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"
                  ></path>
                </svg>
            <span className="flex-1 ml-3 text-left whitespace-nowrap"
              >Mitgliedsverwaltung</span>
            <svg
              aria-hidden="true"
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <ul id="dropdown-pages" className={(toggleMemberships ? "" : "hidden") + " py-2 space-y-2"}>
            <li>
              <Link
                to="/create"
                className={(location.pathname === '/create' ? "bg-emerald-100 hover:bg-emerald-100" : "") + "  flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 "}
                >Mitglied erstellen</Link>
            </li>
            <li>
              <Link
                to="/types"
                className={(location.pathname === '/types' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 "}
                >Mitgliedstypen</Link>
            </li>
            <li>
              <Link
                to="/birthdays"
                className={(location.pathname === '/birthdays' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " flex items-center p-2 pl-11 w-full text-base font-medium text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 "}
                >Geburtstage</Link>
            </li>
          </ul>
          </li>
        }
      </ul>
      <ul
        className="mt-5 space-y-2"
      >
        <li>
          <Link
            to="/beitragsverwaltung"
            className={(location.pathname === '/beitragsverwaltung' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100"}
          >
            <svg
              aria-hidden="true"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm2.5 3a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.207.293a1 1 0 00-1.414 0l-6 6a1 1 0 101.414 1.414l6-6a1 1 0 000-1.414zM12.5 10a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="ml-3">Beitragsverwaltung</span>
          </Link>
        </li>
        <li>
          <Link
            to="/lager"
            className={(location.pathname === '/lager' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100"}
          >
            <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z"></path>
                  <path
                    fill-rule="evenodd"
                    d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
            <span className="ml-3">Lagerverwaltung</span>
          </Link>
        </li>
        <li>
          <Link
            to="/berichte"
            className={(location.pathname === '/berichte' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100"}
          >
            <svg
              aria-hidden="true"
              className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 "
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z"></path>
              <path
                fill-rule="evenodd"
                d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="ml-3">Abgaben & Berichte</span>
          </Link>
        </li>
        <li>
          <Link
            to="/finanzen"
            className={(location.pathname === '/finanzen' ? "bg-emerald-100 hover:bg-emerald-100" : "") +  " flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100"}
          >
            <svg
              aria-hidden="true"
              className={"flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 "}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
              ></path>
              <path
                fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <span className="ml-3">Finanzen</span>
          </Link>
        </li>
        <li>
          <Link
            to="/einstellungen"
            className={(location.pathname === '/einstellungen' ? "bg-emerald-100 hover:bg-emerald-100" : "") + " flex items-center p-2 text-base font-medium text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100"}
          >
            <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 "
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            <span className="ml-3">Einstellungen</span>
          </Link>
        </li>
      </ul>
      
    </div>
  </aside>
  )
}

export default SideBar